<template>
  <div class="home">
    <div class="g">
      <div class="s">
        <router-link class="s2 s21" to="/">Echo<i class="el-icon-moon-night"></i>bizi</router-link>
        <router-link class="s2" to="/Su">竖屏壁纸</router-link>
        <router-link class="s2 s23" to="/about">横屏壁纸</router-link>
      </div>
      <div class="box">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-card shadow="hover" style="width: 100%;">
              <p class="sj">{{ nyr }}</p>
              <p class="sj2">{{ sj }}</p>
              <i class="el-icon-s-promotion"></i>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      currentTime: new Date() // 初始化为当前时间
    };
  },
  computed: {
    sj() {
      const date = new Date(this.currentTime);
      // 北京时间是UTC+8
      date.setHours(date.getHours());
      return date.toLocaleTimeString(); // 只返回时间部分
    },
    nyr() {
      const date = new Date(this.currentTime);
      // 获取年、月、日、星期几
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要加1
      const day = String(date.getDate()).padStart(2, '0');
      const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const weekDay = weekDays[date.getDay()];

      return `${year}年${month}月${day}日 ${weekDay}`;

    }

  },
  mounted() {
    this.updateCurrentTime();
    setInterval(this.updateCurrentTime, 1000); // 每秒更新一次当前时间
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = new Date(); // 更新当前时间
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'UnidreamLED';
  src: url('@/assets/UnidreamLED_爱给网_aigei_com.ttf'),
}

* {
  padding: 0px;
  margin: 0px;
}

.g {
  width: 100%;
  text-align: center;
  background-image: url('../assets/A20.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: absolute;
  top: 0px;
  z-index: -222;
}

.box {
  width: 300px;
  margin: 0px auto;
  overflow: hidden;
  margin-right: 29.5%;
  margin-top: 190px;
}

.sj {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 10px;
}

.sj2 {
  font-size: 3.25rem;
  letter-spacing: 2px;
  font-family: 'UnidreamLED';
  color: #fff;
}

.el-col-10 {
  width: 100%
}

.el-card {
  border: 1px solid rgb(100, 100, 100);
  background-color: rgba(0, 0, 0, 0.4);
  /* 半透明 */
  backdrop-filter: blur(10px);
  border-radius: 8px;
}

.s {
  overflow: hidden;
}

.s2 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 2rem;
}

.s21 {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 3.5rem;
  color: rgba(0, 0, 0, 0.4);
}

.s23 {
  margin-left: 20px;
}

.el-icon-moon-night {
  margin: 0px 8px 0px 15px;
  color: rgba(60, 57, 248, 0.4);
}

.el-icon-s-promotion:before {
  font-size: 30px;
  background: linear-gradient(to right, #5fc4ff, #8f7bfe, #d47bfe);
  -webkit-background-clip: text;
  color: transparent;
}
</style>